import React, { useContext } from "react";
import { graphql } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Layout from "../components/layout";
import { article } from "../styles/modules/post_template.module.css";
import { GatsbyImage } from "gatsby-plugin-image";
import { transformSlugToArrBreadcrumbs } from "../helpers/transformSlugToArr";
import BreadCrumbs from "../components/BreadCrumps";
import WebPageJsonLD from "../components/SEO/WebPageJsonLD";
import { ContextLocale } from "../context/ContextLocale";
// import AdWithPlaceholder from "../components/Ads/AdWithPlaceholder";
// import AdManaWithPlaceholder from "../components/Ads/AdManaWithPlaceHolder";
import { getCannonicalURL } from "../../coloreshtml/template/helpers/getCannonicalURL";
import { BasicHeadTags } from "../components/SEO/BasicHeadTags";

function PostTemplate({ data }) {
  const { mdx } = data;
  const { setLang } = useContext(ContextLocale);
  const breadCrumbsList = transformSlugToArrBreadcrumbs(
    mdx.frontmatter.slug,
    mdx.frontmatter.locale
  );
  setLang(mdx.frontmatter.locale);

  return (
    <Layout locale={mdx.frontmatter.locale}>
      <article className={article}>
        <BreadCrumbs breadCrumbsList={breadCrumbsList} />

        <h1>{mdx.frontmatter.title}</h1>
        <p>{mdx.frontmatter.metaDescription}</p>
        <div style={{ maxWidth: "800px" }}>
          <GatsbyImage
            image={
              mdx.frontmatter.featuredImage.childImageSharp.gatsbyImageData
            }
            alt={mdx.frontmatter.title}
          />
        </div>
        <div style={{ maxWidth: "800px" }}>
          {/* <AdManaWithPlaceholder
            path="/22250597679/ptdc_banner_bottom"
            size={[
              [300, 250],
              [970, 250],
              [728, 250],
              [970, 90],
              [728, 90],
            ]}
            id="ptdc_banner_bottom"
          /> */}
        </div>
        <MDXRenderer>{mdx.body}</MDXRenderer>
      </article>
    </Layout>
  );
}

export const Head = ({ data }) => {
  const { mdx } = data;

  const breadCrumbsList = transformSlugToArrBreadcrumbs(
    mdx.frontmatter.slug,
    mdx.frontmatter.locale
  );

  const canonicalURL = getCannonicalURL(mdx.frontmatter.slug);

  return (
    <>
      <BasicHeadTags
        title={mdx.frontmatter.metaTitle}
        description={mdx.frontmatter.metaDescription}
        type="Article"
        image={mdx.frontmatter.featuredImage.publicURL}
        canonicalUrl={canonicalURL}
        slug={mdx.frontmatter.slug}
      />

      <WebPageJsonLD
        title={mdx.frontmatter.metaTitle}
        imagePublicURL={mdx.frontmatter.featuredImage.publicURL}
        imageWidth={
          mdx.frontmatter.featuredImage.childImageSharp.original.width
        }
        imageHeight={
          mdx.frontmatter.featuredImage.childImageSharp.original.height
        }
        slug={mdx.frontmatter.slug}
        description={mdx.frontmatter.metaDescription}
        breadcrumbs={breadCrumbsList}
      />
    </>
  );
};

export default PostTemplate;

export const data = graphql`
  query ($id: String!) {
    mdx(id: { eq: $id }) {
      body
      id
      frontmatter {
        title
        metaTitle
        metaDescription
        slug
        locale
        featuredImage {
          publicURL
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, quality: 100)
            original {
              height
              width
            }
          }
        }
      }
    }
  }
`;
